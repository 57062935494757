import React from "react";
import './profile.css';
import { useState, useEffect } from "react";
import  Field from "../../components/field/Field";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Profile = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { profileId } = useParams();
  //const [formData, setFormData] = useState({departure: "", arrival: "", estimatedDate: "", totalSeats: "" });
  const [offeredRides, setOfferedRides] = useState([]);
  const [bookedRides, setBookedRides] = useState([]);
  const [profileUser, setProfileUser] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [isBookingMode, setIsBookingMode] = useState(false);
  //const [error, setError] = useState("");
  const [offeredRideError, setOfferedRideError] = useState("");
  const [bookedRideError, setBookedRideError] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const getProfile = async () => {
      try {
        if (profileId && profileId !== loginUser._id) {
          const response = await axios.get(`/api/v1/user/${profileId}`);

          setProfileUser(response.data.user);
        } else {
          setProfileUser(loginUser);
        }        
      } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            setOfferedRideError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching rides');
            setOfferedRideError('⚠️ An error occurred while fetching rides');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getProfile();
  }, [profileId, loginUser, onLogout, navigate]);

  useEffect(() => {
    const getOfferedRide = async () => {
      try {
        let response; 
        if (profileId && profileId !== loginUser._id) {
          response = await axios.get(`/api/v1/ride/${profileId}`);
        } else {
          response = await axios.get(`/api/v1/ride/own`);
        }

        // Use optional chaining and provide fallback values
        const temp_rides = response.data.rides || [];
        
        setOfferedRides(temp_rides);

        // Clear the error if there are rides
        if (temp_rides.length > 0) {
          setOfferedRideError(null);
        } else {
          setOfferedRideError('⚠️ No rides posted yet.');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            setOfferedRideError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching rides');
            setOfferedRideError('⚠️ An error occurred while fetching rides');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getOfferedRide();
  }, [profileId, loginUser, onLogout, navigate]);

  useEffect(() => {
    const getBookedRide = async () => {
      try {
        let response; 
        if (profileId && profileId !== loginUser._id) {
          response = await axios.get(`/api/v1/ride/bookedRides/${profileId}`);
        } else {
          response = await axios.get(`/api/v1/ride/bookedRides/${loginUser._id}`);
        }

        // Use optional chaining and provide fallback values
        const temp_rides = response.data.bookedRides || [];
        
        setBookedRides(temp_rides);

        // Clear the error if there are rides
        if (temp_rides.length > 0) {
          setBookedRideError(null);
        } else {
          setBookedRideError('⚠️ No rides booked yet.');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            setBookedRideError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching bookings');
            setBookedRideError('⚠️ An error occurred while fetching bookings');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getBookedRide();
  }, [profileId, loginUser, onLogout, navigate]);

  const handleBookingClick = () => {
    setIsBookingMode(true); // Set the state to true when the user clicks Sign Up
  };

  const handleOfferedClick = () => {
    setIsBookingMode(false); // Set the state to false when the user clicks Login
  };
  
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="profile__ride-container"> 
        <h2 className="gradient__text">Loading Rides ...</h2>
      </div>
    );
  }

  const handleGetSingleRide = (rideId) => {
    console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
    
    navigate(`/ride/${rideId}`);
  };

  return (
    <div className="gpt3__feed section__padding">
      {
      <div className="profile__form">
        <div className="profile__form-content">
          <h1 className="gradient__text">User Profile</h1>

          <div className="profile__form-content__field">
            <img src={profileUser.avatar} alt="" />
            <div className="profile__form-content__field-div">
              <h3>Name: </h3><p>{profileUser.userName}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Email: </h3><p className="lowercase-letter">{profileUser.email}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Phone Number: </h3><p>{profileUser.phoneNumber || "Nil"}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Role: </h3><p>{profileUser.role}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Gender: </h3><p>{profileUser.gender}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Joined Date: </h3><p>{new Date(profileUser.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="profile__ride-container">
        <h2 className="gradient__text">{`${profileUser?.userName}'s Rides` || "User Rides"}</h2>
        <div className="profile__ride-container__slide-controls">
          <input type="radio" name="slide" id="offer" checked={!isBookingMode} onChange={handleOfferedClick} />
          <input type="radio" name="slide" id="booking" checked={isBookingMode} onChange={handleBookingClick} />
          <label htmlFor="offer" className={`slide`} onClick={handleOfferedClick}> Offered </label>
          <label htmlFor="booking" className={`slide`} onClick={handleBookingClick}> Booked </label>
          <div className={`slider-tab ${isBookingMode ? 'shift-right-slider' : ''}`}></div>
        </div>

        <div className={`profile__ride-container__wrapper ${isBookingMode ? 'shift-right' : ''}`}>
          {/* Offered Rides */}
          <div className={`profile__ride-container__wrapper-slider`}>                
            <div className={`profile__ride-container__wrapper-slider__content ${isBookingMode ? 'hide' : ''}`}>
            {offeredRideError && <p className="">{offeredRideError}</p>}
            {offeredRides.length &&
              (offeredRides.map((element, idx) => {
                const { _id, arrival, departure, estimatedDate, /*totalSeats*/} = element;
                
                return (
                  <div onClick={() => handleGetSingleRide(_id)} className={`profile__ride-container__wrapper-slider__content-post`}>
                    <Field heading="To" text={arrival}></Field>
                    <Field heading="From" text={departure}></Field>
                    <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>
                  </div>
                );
              }))
            }      
            </div>
          </div>
          
          {/* Booked Rides */}
          <div className="profile__ride-container__wrapper-slider">
            <div className={`profile__ride-container__wrapper-slider__content  ${isBookingMode ? '' : 'hide'}`}>
            {bookedRideError && <p className="">{bookedRideError}</p>}
            {bookedRides.length &&
              (bookedRides.map((element, idx) => {
                const { _id, arrival, departure, estimatedDate, /*totalSeats*/} = element;
                
                return (
                  <div onClick={() => handleGetSingleRide(_id)} className={`profile__ride-container__wrapper-slider__content-post`}>
                    <Field heading="To" text={arrival}></Field>
                    <Field heading="From" text={departure}></Field>
                    <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>
                  </div>
                );
              }))
            }      
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default Profile;