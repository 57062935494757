import React from "react";
import { useState, useEffect } from "react";
import './admin.css';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCircleCheck, faUserCheck, faCircleQuestion, faEye, faBan} from '@fortawesome/free-solid-svg-icons';


const Admin = ( { isLoggedIn, loginUser, onLogout } ) => {
  const [userList, setUserList] = useState([]);
  const [deleteInProcess, setDeleteInProcess] = useState(false); // Ride Deleting state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const getAllUsers = async () => {
      try {
        console.log('Fetching users list ');
        const response = await axios.get(`/api/v1/user/`);
        
        setUserList(response.data.userList);
      } catch (error) {
        console.log(`[FrontEnd] error in getAllUsers, ${error}`);

        if (error.response && error.response.status === 401) {
          console.log('Session expired, redirecting to login...');
          onLogout();
          navigate('/login'); // Redirect user to login
        } else if (error.response) {
          console.log(error.response.data.message);
          setError(error.response.data.message);
        } else {
          console.log('⚠️ An error occurred while fetching users');
          setError('⚠️ An error occurred while fetching users');
        }    
      } finally {
        // Ensure loading is reset in all cases
        setLoading(false);
      }
    };

    getAllUsers();
  }, [onLogout, navigate]);

  if (!isLoggedIn || loginUser.role !== 'admin') {
    navigate('/');
    return null;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="admin__details section__padding gradient__bg" id="ride">
        <div className="admin__details-heading">
          <h1 className="gradient__text">Loading Users List...</h1>
        </div>  
      </div>
    );
  }

  // Show an indicator while deleting in process
  if (deleteInProcess) {
    return (
      <div className="admin__details section__padding gradient__bg" id="ride">
        <div className="admin__details-heading">
          <h1 className="gradient__text">Deleting User...</h1>
        </div>  
      </div>
    );
  }
    
  const onBtnClickDeleteUser = async (userID) => {
    try {
      setDeleteInProcess(true);

      await axios.post(`/api/v1/user/deleteUser/${userID}?_method=PUT`);

      navigate('/admin');
    } catch (error) {
      console.log(error);

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
        console.log(error.response.data.message);
        setError(error.response.data.message);
      } else {
        console.log('⚠️ An error occurred while deleting user');
        setError('⚠️ An error occurred while deleting user');
      } 
    } finally {
      // Ensure loading is reset in all cases
      setLoading(false);
      setDeleteInProcess(false);
    }
  };

  const onBtnClickApproveUser = async (userID) => {
    try {
      // Optimistically update isApproved
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isApproved: true}
          : user
        )
      );

      const response = await axios.post(`/api/v1/user/approveUser/${userID}?_method=PUT`);

      // Update isApproved from the response
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isApproved: response.data.updatedApproval}
          : user
        )
      );
      
    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isApproved: false}
          : user
        )
      );

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
        console.log(error.response.data.message);
        setError(error.response.data.message);
      } else {
        console.log('⚠️ An error occurred while approving user');
        setError('⚠️ An error occurred while approving user');
      } 
    } finally {
      // Ensure loading is reset in all cases
      setLoading(false);
    }
  };

  const handleToggleBan = async (userID) => {
    try {
      // Optimistically update isBanned
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isBanned: !user.isBanned}
          : user
        )
      );

      const response = await axios.post(`/api/v1/user/toggleBan/${userID}?_method=PUT`);

      // Update isBanned from the response
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isBanned: response.data.updatedBan}
          : user
        )
      );

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setUserList((prevList) =>
        prevList.map((user) =>  
          (user._id === userID) 
          ? {...user, isBanned: !user.isBanned}
          : user
        )
      );

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
        console.log(error.response.data.message);
        setError(error.response.data.message);
      } else {
        console.log('⚠️ An error occurred while banning user');
        setError('⚠️ An error occurred while banning user');
      } 
    } finally {
      // Ensure loading is reset in all cases
      setLoading(false);
    }
  };
  
  return (
    <div className="admin__details section__padding gradient__bg" id="ride">
      <>
        <div className="admin__details-heading">
          <h1 className="gradient__text">Users list:</h1>
        </div>
        
        {error !== "" && <div className="admin__details-passenger__content"><label className="error">{error}</label></div>}
        {userList.map((el, idx) => {
          return (
            <div className="admin__details-passenger__content">
              <Link to={`/profile/${el._id}`}><p>{`${idx+1}.  ${el.userName}`}</p></Link>              
              <div>
                <Link to={`/profile/${el._id}`}><FontAwesomeIcon icon={faEye}/></Link>
                {el.isBanned
                ? <Link><FontAwesomeIcon icon={faBan} onClick={() => handleToggleBan(el._id)}/></Link>
                : <Link><FontAwesomeIcon icon={faCircleCheck} onClick={() => handleToggleBan(el._id)}/></Link>
                }
                {el.isApproved
                ? <Link><FontAwesomeIcon icon={faUserCheck} onClick={() => onBtnClickApproveUser(el._id)}/></Link>
                : <Link><FontAwesomeIcon icon={faCircleQuestion} onClick={() => onBtnClickApproveUser(el._id)}/></Link>
                }
                <Link><FontAwesomeIcon icon={faTrashCan} onClick={() => onBtnClickDeleteUser(el._id)}/></Link>
              </div>
            </div>
          );
        })}
      </>      
    </div>
  );
}

export default Admin;